@import '../variables.scss';

.health-information-page {
  .header-bg {
    path.bg {
      fill: $color-primary-2;
    }
  }

  .page-icon-container {
    border: 2px solid $color-primary-2;
  }

  .health-information-icon {
    height: 43px;
    width: 33px;
  }

  .health-information-icon {
    path.fg {
      fill: $color-primary-2;
    }
  }

  .health-information-search-control {
    display: flex;
    margin: 20px 0;
  }
  .search-information-input {
    color: $text-color-background;
    font-family: $font-family-secondary;
    font-size: 16px;
    height: 40px;
    -webkit-appearance: none !important;
    // -webkit-box-shadow: 0 2px 5px rgba(33, 36, 58, 0.3);
    box-shadow: 0 2px 5px rgba(33, 36, 58, 0.3);
    border-radius: 20px;
    background-color: #eff1f3;
    border: none;
    padding-left: 20px;
    background-color: $color-white;
  }

  .search-button {
    width: 48px;
    height: 40px;
    border-radius: 15px;
    background-color: $color-primary-2;
    margin-left: 20px;
    border: none;
  }

  .search-button:hover {
    cursor: pointer;
  }
  .nhs-section-header {
    background-color: $color-white;
  }

  .accordion-header:hover {
    cursor: pointer;
  }

  .accordion-header-title {
    font-family: $font-family-secondary;
    font-weight: 600;
    font-size: 16px;
  }

  .health-info-travel {
    background-color: rgba($color-primary-2, 0.9);
  }

  .health-info-wellness {
    background-color: rgba($color-primary-2, 0.8);
  }

  .accordion-title-icon {
    margin-right: 20px;
  }
}
