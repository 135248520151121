// screen sizes
$mobile: 479px !default;
$tablet: 769px !default;
$laptop: 1025px !default;
$desktop: 1281 !default;

// colors
$color-primary-1: #2cabe1;
$color-primary-2: #2cabe1;

$color-white: #fff;
$color-background: #eff1f3;
$color-red: #ef4339;

/// text on background
$text-color-background: #46494c;

/// text on primary color
$text-color-primary: #fff;

// fonts
$font-family-primary: 'Lato', sans-serif;
$font-family-secondary: 'Montserrat', sans-serif;
