@import '../variables';
@import '../mixins.scss';

.request-appointment-page {
  .header-text {
    width: 250px;
    text-align: right;
  }
  .clock-icon {
    width: 37px;
    height: 37px;

    path.fg {
      fill: $color-primary-1;
    }

    path.bg {
      fill: $color-white;
    }
  }

  .section-header {
    text-align: center;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    height: 100%;
  }

  .button-container .icon-container {
    height: 60px;
  }

  .button-tile-container {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
  }
  .phone-consultation-icon {
    width: 44px;
    height: 44px;
  }

  .phone-consultation-icon path.fg {
    fill: $color-primary-1;
  }

  .phone-consultation-icon path.bg {
    fill: $color-primary-1;
  }

  .appointment-table {
    width: 100%;
  }

  .appointment-table tr:last-child {
    border-bottom: 2px solid $color-white;
  }

  .appointment-table tr {
    height: 30px;
  }

  .table-container {
    background-color: $color-primary-1;
  }

  .appointment-table tr td {
    padding-left: 10px;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    color: $text-color-primary;
  }

  .footnote {
    color: $text-color-primary;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
  }

  .form {
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 20px;
  }

  .btn.btn-request-consultation {
    background-color: $color-primary-1;
    color: $text-color-primary;
  }

  .btn.input-file-label {
    max-width: 200px;
  }
}

@include untilSize(768px) {
  .request-appointment-page .btn-request-consultation {
    width: 100%;
  }
}

@include fromSize($tablet) {
  .request-appointment-page {
    .table-container {
      padding: 10px 100px;
    }
    .header-text {
      width: 100%;
      text-align: center;
    }
  }
}

@include fromSize($laptop) {
  .request-appointment-page {
    .table-container {
      padding: 10px 300px;
    }

    .form {
      margin-top: 20px;
      width: 800px;
      margin: 20px auto 0 auto;
    }
  }
}
