@import '../variables.scss';

.btn {
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: $font-family-primary;
  border: none;
  background-color: $color-white;
}

.btn.flat {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.btn.flat.btn.btn-primary-2-inversed {
  color: $color-primary-2;
  background-color: $text-color-primary;
}

.btn:hover {
  cursor: pointer;
}

.btn.btn-primary-1 {
  background-color: $color-primary-1;
  color: $text-color-primary;
}

.btn.btn-primary-1.btn-inversed {
  border: 1px solid $color-primary-1;
  background-color: $color-white;
  color: $text-color-background;
}
.btn.btn-primary-2 {
  background-color: $color-primary-2;
  color: $text-color-primary;
}

.btn.btn-bold {
  font-weight: 700;
}
