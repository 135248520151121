@import '../variables';
@import '../mixins.scss';

.home-page .hero-unit {
  width: 100%;
  max-height: 70vh;
  position: relative;
}

.hero-unit img.hero-banner-mobile {
  max-height: 60vh;
  object-fit: cover;
  width: 100%;
}

.home-page .button-tile-container {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.home-page .button-tile-primary {
  background-color: $color-primary-1;
  width: 100%;
}

.home-page .button-tile-secondary {
  background-color: $color-primary-2;
  display: flex;
  flex: 1;
  margin-top: 20px;
}

.home-page .online-booking-form-link {
  color: #000;
  text-decoration: underline;
}

.home-page .hero-logo-container {
  top: 12%;
  left: 8%;
  position: absolute;
  z-index: 1;
}
.home-page .hero-unit .hero-logo {
  // position: absolute;
  // z-index: 1;
  height: 54px;
  // top: 12%;
  // left: 8%;
}

.home-page .hero-unit .hero-text {
  font-family: 'Rubik', sans-serif;
  color: #000;
  font-size: 30px;
  font-weight: 500;
  // position: absolute;
  // top: 30%;
  // left: 8%;
}

.home-page .hero-unit .hero-description {
  margin-top: 10px;
  // position: absolute;
  // top: 60%;
  // left: 8%;
  font-family: $font-family-primary;
}

.home-page .button-icon {
  width: 44px;
  height: 39px;
  margin-bottom: 20px;
}

.home-page .thethoscope-icon path.fg,
.home-page .heart-icon path.fg,
.home-page .medical-history-icon path.fg {
  fill: $color-white;
}

.home-page .thethoscope-icon path.bg {
  fill: $color-primary-1;
}

.home-page .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.home-page .bottom-container {
  display: flex;
  align-items: center;
}

.home-page .appointment-info {
  flex: 1;
  align-items: center;
}

.home-page .button-tile-container .button-tile:last-child {
  margin-left: 10px;
}

.home-page .consultation-info-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $color-primary-1;
}

.home-page .phone-consultation-icon {
  width: 50px;
  height: 50px;
}

.home-page .phone-consultation-icon path.fg {
  fill: $color-white;
}

.home-page .consultation-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-primary;
}

.home-page .consultation-info-description {
  margin-left: 20px;
}
.home-page .consultation-phone {
  font-size: 40px;
  font-weight: 600;
}

.home-page .header {
  display: flex;
  justify-content: center;
  background-color: $color-white;
  padding: 10px;
}

.home-page .header img {
  width: 417px;
  height: 60px;
  padding: 10px 0px;
}

.home-page .footer {
  font-family: $font-family-primary;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}

.home-page .footer .footer-text {
  padding-bottom: 32px;
  margin-left: 10px;
}

.home-page .footer .medsol-logo {
  height: 80px;
  display: none;
}

@include untilSize(768px) {
  .hero-banner-desktop {
    display: none;
  }
}

@include fromSize(1024px) {
  .home-page .hero-logo-container {
    top: 40%;
  }
}

@include untilSize($laptop) {
  .home-page .header {
    display: none;
  }

  .home-page .appointment-info {
    display: none;
  }
}

@include fromSize($laptop) {
  .home-page .button-tile.button-tile-primary,
  .home-page .button-tile.button-tile-secondary {
    flex: 1;
    width: auto;
    margin-top: 0;
  }

  .home-page .hero-logo {
    display: none;
  }

  .home-page .button-tile.button-tile-secondary {
    margin-left: 10px;
  }

  .home-page .bottom-container {
    position: relative;
    z-index: 2;
    background-color: rgba($color: $color-white, $alpha: 0.4);
    padding: 20px 0;
  }

  .home-page .footer .medsol-logo {
    display: block;
  }

  .home-page .footer {
    background-color: $color-white;
  }
}

@media screen and (max-width: 415px) {
  .home-page .hero-unit .hero-logo {
    height: 50px;
  }

  .home-page .hero-unit {
    width: 100%;
    max-height: 40vh;
    position: relative;
  }

  .home-page .bottom-container {
    // margin-top: -40px;
    position: relative;
  }
}

@media screen and (orientation: landscape) and (max-width: 667px) {
  .hero-unit img.hero-banner-mobile {
    max-height: 120vh;
    object-fit: cover;
    width: 100%;
  }

  .home-page .bottom-container {
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px 0;
    margin-top: -70px;
  }

  .home-page .button-tile.button-tile-primary {
    margin-right: 10px;
  }
  .home-page .button-tile.button-tile-primary,
  .home-page .button-tile.button-tile-secondary {
    flex: 1 1;
    width: auto;
    margin-top: 0;
  }

  .home-page .hero-unit .hero-text {
    top: 55%;
  }
}

@media screen and (orientation: landscape) and (min-width: 668px) and (max-width: 812px) {
  .hero-unit img.hero-banner-mobile {
    max-height: 100vh;
    object-fit: cover;
    width: 100%;
  }

  .home-page .bottom-container {
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px 0;
    margin-top: -60px;
  }

  .home-page .button-tile.button-tile-primary {
    margin-right: 10px;
  }
  .home-page .button-tile.button-tile-primary,
  .home-page .button-tile.button-tile-secondary {
    flex: 1 1;
    width: auto;
    margin-top: 0;
  }

  .home-page .hero-unit .hero-text {
    top: 50%;
  }
}
