@import 'reset.scss';
@import 'helpers.scss';

@import 'ui/button-tile.scss';
@import 'ui/button.scss';
@import 'ui/common.scss';
@import 'ui/form.scss';
@import 'ui/accordion.scss';
@import 'app/app.scss';
@import 'app/page-header.scss';
@import 'pages/all.scss';

.pattern-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAMCAYAAACJOyb4AAABT0lEQVQ4T+2T246CQBBEaxTxfkH0/z/PGLxiFFBxNqeTIeruxuz7TmIw0N1VfSjcarXy8/lccRzrer2qqiq1Wi31ej21222dTiflea77/a4oijSZTDQej1XXtcqytCu91FOz2+10uVys12VZ5mmmKU1TOefEQWSz2eh2u2k2m6nf79uw/X5vIsvlUt1u12q99zoejzZ4NBo14s5773HMIJpxgBvuMZStgmAYhMDhcDARftTidLFYmIlw3Hq99kmSqNPpWBECFOKKxvP53GDh/nQ61XA4NARsF7BQ/3g8bLNvWHCJSHCJUJZlDRY2QhjHGAELrMMBy3a71WAwMMSIvmDBScDC/09YGM521GIKwRcs72l5x/JbWsBCLSjYJJj6c1rARTMOYQr7T2lB1LAQN9JSFIW9SB6ECD6/h5AWuIdIPqcFQUw0afn/iH76iL4Aa/iTBpJb2ysAAAAASUVORK5CYII=');
  background-repeat: repeat;
}

.call-link {
  font-weight: 700;
  text-decoration: underline;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-policy {
  font-family: $font-family-secondary;
  font-size: 12px;
  color: #54585a;
}

.recaptcha-policy a {
  font-family: $font-family-secondary;
  font-size: 12px;
  color: #54585a;
}

.ios-pwa-prompt {
  .prompt-header {
    font-family: $font-family-primary;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .prompt-app-icon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .prompt-app-icon img {
    border-radius: 20px;
    border: 1px solid #ccc;
    width: 90px;
    height: 90px;
  }

  .prompt-text {
    font-family: $font-family-primary;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }

  .prompt-footer {
    font-family: $font-family-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-flow: wrap;
  }

  .prompt-footer img {
    width: 30px;
  }
}
