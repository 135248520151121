@import '../variables.scss';
@import '../mixins.scss';

.header-bg.header-bg-mobile {
  width: 140%;
  height: 100%;
}

.page-header-container {
  height: 250px;
  position: relative;
  overflow: hidden;
}

.header-bg {
  width: 120%;
  height: 100%;
  path.bg {
    fill: $color-primary-1;
  }
}

.logo-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}
.back-btn:hover {
  cursor: pointer;
}
.back-icon path.bg {
  fill: $color-white;
}
.logo {
  width: 90px;
  height: 30px;
}

.header-text {
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 26px;
  color: $color-white;
  position: absolute;
  top: 40%;
  right: 20px;
}

.page-icon-container {
  width: 70px;
  height: 70px;
  border: 2px solid $color-primary-1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  position: absolute;
  top: calc(40% + 13px);
  left: 50px;
  border-radius: 50%;
}

@include laptop {
  .header-text {
    width: 100%;
    text-align: center;
    right: 0;
  }

  .page-icon-container {
    top: calc(40% + 28px);
    left: 120px;
  }
}
