@import '../variables.scss';
@import '../mixins.scss';

.e-consultations-page {
  .e-consultation-icon {
    width: 39px;
    height: 51px;
    margin-left: 8px;
  }

  .e-consultation-icon path.fg {
    fill: $color-primary-1;
  }

  .appointment-table {
    width: 100%;
  }

  .appointment-table tr:last-child {
    border-bottom: 2px solid $color-white;
  }

  .appointment-table tr {
    height: 30px;
  }

  .table-container {
    padding-bottom: 80px;
  }
  .appointment-table tr td {
    padding-left: 10px;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    color: #46494c;
  }

  .footnote {
    color: #9b9b9b;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
  }

  .section:last-child {
    background-color: $color-white;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .button-tile-primary {
    background-color: $color-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27);
    flex: 1;
    margin: 30px;
    margin-top: -80px;
  }

  .button-tile-primary .btn {
    border: 1px solid $color-primary-1;
  }

  .phone-consultation-icon {
    width: 40px;
    height: 39px;
  }

  .phone-consultation-icon path.fg {
    fill: $color-primary-1;
  }

  .content.e-consultation-content {
    padding-top: 0;
  }
}

@include fromSize($tablet) {
  .e-consultations-page {
    .table-container {
      padding: 10px 100px;
      margin-bottom: 80px;
    }
  }
}

@include fromSize($laptop) {
  .e-consultations-page {
    .table-container {
      padding: 10px 300px;
    }

    .form {
      margin-top: 20px;
      width: 800px;
      margin: 20px auto 0 auto;
    }
  }
}
