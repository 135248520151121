@import '../variables';
@import '../mixins.scss';

.message-dr-page {
  .message-icon {
    width: 37px;
    height: 37px;
  }

  .message-icon path.fg {
    fill: $color-primary-1;
  }

  .appointment-table {
    width: 100%;
  }

  .appointment-table tr:last-child {
    border-bottom: 2px solid $color-white;
  }

  .appointment-table tr {
    height: 30px;
  }

  .table-container {
    background-color: $color-primary-1;
  }

  .appointment-table tr td {
    padding-left: 10px;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    color: $text-color-primary;
  }

  .footnote {
    color: $text-color-primary;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
  }

  .form {
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 20px;
  }

  .btn.btn-request-consultation {
    background-color: $color-primary-1;
    color: $text-color-primary;
  }

  .btn.input-file-label {
    max-width: 200px;
  }
}

@include fromSize($tablet) {
  .message-dr-page {
    .table-container {
      padding: 10px 100px;
    }

    .message-dr-text {
      padding: 20px;
    }
  }
}

@include untilSize(768px) {
  .message-dr-page .btn-request-consultation {
    width: 100%;
  }
}
@include fromSize($laptop) {
  .message-dr-page {
    .table-container {
      padding: 10px 300px;
    }

    .form {
      margin-top: 20px;
      width: 800px;
      margin: 20px auto 0 auto;
    }
  }
}
