@import 'variables.scss';
@import 'mixins.scss';

.hide-mobile {
  @include mobile {
    display: none;
  }
}

.hide-tablet {
  @include tablet {
    display: none;
  }
}

.hide-laptop {
  @include laptop {
    display: none;
  }
}

.hide-desktop {
  @include desktop {
    display: none;
  }
}

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex';

@each $display in $displays {
  .display-#{$display} {
    display: #{$display};
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.font-primary {
  font-family: $font-family-primary;
}

.font-secondary {
  font-family: $font-family-secondary;
}

$pad: 10;

// margin helpers
.mt-1 {
  margin-top: #{$pad}px;
}

.ml-1 {
  margin-left: #{$pad}px;
}

.mr-1 {
  margin-right: #{$pad}px;
}

.mb-1 {
  margin-bottom: #{$pad}px;
}

.mt-2 {
  margin-top: #{2 * $pad}px;
}

.mb-2 {
  margin-bottom: #{2 * $pad}px;
}

// padding helpers
.pt-1 {
  padding-top: #{$pad}px;
}

.pl-1 {
  padding-left: #{$pad}px;
}

.pr-1 {
  padding-right: #{$pad}px;
}

.pb-1 {
  padding-bottom: #{$pad}px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-full {
  width: 100%;
}

.opacity-75 {
  opacity: 0.75;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
