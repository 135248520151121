@import '../variables';
@import '../mixins.scss';

.how-it-works-page {
  .accordion-pane {
    border-top: 2px solid #f1f3f6;
    margin: 0 20px;
  }
  .accordion-header {
    background-color: $color-white;
    font-weight: 600;
  }

  .expanded-icon,
  .collapsed-icon {
    width: 24px;
    height: 24px;
    path {
      fill: $color-primary-1;
    }
  }

  .accordion-content {
    padding: 0 20px;
  }

  .accordion-header-title {
    color: #46494c;
    font-family: $font-family-primary;
  }

  .question {
    font-weight: 600;
    font-family: $font-family-primary;
    font-size: 14px;
  }

  .answer {
    margin: 10px 0;
    font-family: $font-family-primary;
    font-size: 14px;
  }
}
