@import '../variables.scss';

.accordion-header {
  display: flex;
  height: 65px;
  align-items: center;
  padding: 20px;
  background-color: $color-primary-2;
  color: $text-color-primary;
}

.accordion-header-title {
  display: flex;
  flex: 1;
  align-items: center;
}

.accordion-header-icon {
  width: 24px;
  height: 24px;
}

.accordion-content {
  padding: 10px;
}

.accordion-content-container {
  transition: max-height 0.6s ease;
  overflow: hidden;
}

.accordion-pane.expanded .accordion-content-container {
  display: block;
  height: auto;
}

.accordion-pane.collapsed .accordion-content-container {
  display: none;
  height: auto;
}
