@import '../variables.scss';
@import '../mixins.scss';

.gp-consultations-page {
  .stethoscope-icon {
    width: 36px;
    height: 31px;
  }

  .stethoscope-icon path.bg {
    fill: $color-white;
  }

  .stethoscope-icon path.fg {
    fill: $color-primary-1;
  }

  .speak-to-doctor-section {
    position: relative;
  }

  .speak-to-doctor-section-background {
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70%;
  }

  .button-icon {
    width: 44px;
    height: 39px;
    margin-bottom: 20px;
  }

  .button-icon.video-consult {
    width: 35px;
    height: 48px;
  }

  .button-icon path.fg {
    fill: $color-primary-1;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    height: 100%;
  }

  .button-container .icon-container {
    height: 60px;
  }

  .button-tile-container {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
  }

  .button-tile-link {
    flex: 1;
    display: flex;
  }

  .button-tile-primary {
    background-color: $color-white;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27);
    flex: 1;
    display: flex;
  }

  .btn-divider-text {
    font-family: $font-family-primary;
    text-align: center;
    color: #46494c;
    font-size: 18px;
    font-weight: 600;
  }

  .button-tile-primary .btn,
  .button-tile-secondary .btn {
    border: 1px solid $color-primary-1;
  }

  .button-tile-secondary {
    background-color: $color-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27);
    display: flex;
    flex: 1;
  }

  .section:last-child {
    background-color: $color-white;
  }

  .phone-consultation-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 20px;
  }

  .phone-consultation-icon path.fg {
    fill: $color-primary-1;
  }

  .phone-consultation-icon path.bg {
    fill: $color-primary-1;
  }

  .button-tile-container {
    justify-content: center;
    // align-items: center;

    .button-tile-primary,
    .button-tile-secondary {
      max-width: 300px;
    }
  }

  // .button-tile.request-appointment-btn {
  //   display: none;
  // }

  .terms-and-conditions a {
    color: #000;
    text-decoration: underline;
  }
}

@media screen and (min-width: 769px) and (max-width: 848px) {
  .gp-consultations-page .button-tile-col:last-child {
    margin-top: 5px;
  }
}
@media screen and (max-width: 769px) {
  .gp-consultations-page .button-container {
    height: auto;
  }
}

@media screen and (min-width: 370px) and (max-width: 414px) {
  .gp-consultations-page .button-tile-col {
    width: 45%;
  }
}

@media screen and (max-width: 325px) {
  .gp-consultations-page .button-tile-container .button-tile {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .gp-consultations-page .button-tile-container .button-tile:first-child {
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }
}

@include tablet {
  .gp-consultations-page {
    .button-tile-col {
      flex-direction: row;
      padding: 0;
    }

    .button-tile-col .button-tile {
      margin-top: 0;
      margin-left: 5px;
    }

    // .button-tile .request-appointment-btn {
    //   display: none;
    // }

    .button-tile.request-appointment-btn {
      display: flex;
    }

    .button-tile-container {
      padding: 10px 100px;
    }
  }
}
