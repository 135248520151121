@import '../variables.scss';
@import '../mixins.scss';

.section-header {
  font-family: $font-family-primary;
  font-size: 21px;
  color: #46494c;
  font-weight: 400;
  padding: 20px;
}

.content {
  padding: 10px;
}

.paragraph {
  color: $text-color-background;
  font-family: $font-family-primary;
  font-size: 16px;
  font-weight: 400;
}

.modal-header {
  margin-bottom: 20px;

  .modal-title {
    font-family: $font-family-primary;
    font-size: 21px;
    color: $text-color-background;
    font-weight: 600;
  }

  .modal-subtitle {
    color: $text-color-background;
    font-family: $font-family-primary;
    font-size: 14px;
    opacity: 0.75;
  }
}

@include laptop {
  .section-header {
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }
}
