.request-confirmed-page {
  .checkmark-icon {
    width: 37px;
    height: 37px;
  }

  .appointment-table {
    width: 100%;
    border-bottom: 2px solid $color-white;
    margin-top: 10px;
  }

  .appointment-table tr:not(:last-child) {
    border-bottom: 2px solid $color-white;
  }

  .appointment-table tr {
    height: 30px;
  }

  .table-container {
    background-color: $color-background;
  }

  .appointment-table tr td {
    padding-left: 10px;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    color: $text-color-background;
  }

  .footnote {
    color: #9b9b9b;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    color: $text-color-background;
    font-weight: 600;
  }

  a.terms {
    color: $text-color-background;
    font-weight: 400;
  }
}

@include fromSize($tablet) {
  .request-confirmed-page {
    .table-container {
      padding: 10px 100px;
    }
  }
}

@include fromSize($laptop) {
  .request-confirmed-page {
    .table-container {
      padding: 10px 300px;
    }
  }
}
