@import '../variables.scss';

.services-near-page {
  .content-primary-2 {
    color: $text-color-primary;
    background-color: $color-primary-2;
    font-family: $font-family-secondary;
  }

  .header-bg {
    path.bg {
      fill: $color-primary-2;
    }
  }

  .page-icon-container {
    border: 2px solid $color-primary-2;
  }
}
