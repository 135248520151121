@import '../variables.scss';

.text-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  height: 40px;
  outline: none;
  font-size: 16px;
  font-family: $font-family-primary;
  color: $text-color-background;
}

.text-input.error {
  border-bottom-color: $color-red;
}

.form-control label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  font-family: $font-family-secondary;
  color: $text-color-background;
}

.form-control {
  margin-bottom: 20px;
}

.error-message {
  color: $color-red;
  font-family: $font-family-secondary;
}

.form-control .error-message {
  font-size: 75%;
}

.form-control label.radio-button {
  cursor: pointer;
  padding-right: 25px;
  display: inline;
}

.radio-group {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.radio-button input {
  opacity: 0;
  position: absolute;
}

.radio-button-control {
  position: relative;
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  border: 1px solid #979797;
  border-radius: 50%;
}

.radio-button input:checked + .radio-button-control:after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 21px;
  height: 21px;
  background-color: $color-primary-1;
  border-radius: 50%;
}

.radio-button input:checked + .radio-button-control {
  border-color: $color-primary-1;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file-label:hover {
  cursor: pointer;
}

.input-file-label span {
  pointer-events: none;
}

.input-file-label {
  text-align: center;
  border: 1px solid #9b9b9b;
}

select {
  background-color: transparent;
  width: 100%;
  height: 40px;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  font-size: 16px;
}

.form-control .native-select-container {
  border-bottom: 1px solid black;
}
