@import '../variables.scss';
@import '../mixins.scss';

.message-sent-page {
  .paper-plane-icon {
    width: 37px;
    height: 37px;
  }

  .paper-plane-icon path.fg {
    fill: $color-primary-1;
  }

  .paper-plane-icon path.bg {
    fill: $color-white;
  }

  .appointment-table {
    width: 100%;
    border-bottom: 2px solid $color-white;
    margin-top: 10px;
  }

  .appointment-table tr:not(:last-child) {
    border-bottom: 2px solid $color-white;
  }

  .appointment-table tr {
    height: 30px;
  }

  .table-container {
    background-color: $color-background;
  }

  .appointment-table tr td {
    padding-left: 10px;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    color: $text-color-background;
  }

  .footnote {
    color: #9b9b9b;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    color: $text-color-background;
    font-weight: 600;
  }

  a.terms {
    color: $text-color-background;
    font-weight: 400;
  }
}

@include fromSize($tablet) {
  .message-sent-page {
    .table-container {
      padding: 10px 100px;
    }

    .message-dr-text {
      padding: 20px;
    }
  }
}

@include fromSize($laptop) {
  .message-sent-page {
    .table-container {
      padding: 10px 300px;
    }
  }
}
