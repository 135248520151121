@import '../variables.scss';

.my-health-page {
  .header-bg {
    path.bg {
      fill: $color-primary-2;
    }
  }

  .page-icon-container {
    border: 2px solid $color-primary-2;
  }

  .heartbeat-icon {
    path.fg {
      fill: $color-primary-2;
    }
  }

  .react-tabs__tab-list {
    display: flex;
    border: none;
    margin: 0;
    background-color: $color-background;
  }

  .react-tabs__tab {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    padding: 0;
    bottom: 0;
  }

  .react-tabs__tab--selected {
    background-color: $color-background;
    border-color: transparent;
    border-radius: 0;
  }

  .tab-label {
    padding: 10px;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    font-family: $font-family-secondary;
  }

  .react-tabs__tab--selected .tab-label {
    color: $color-primary-2;
    border-bottom: 2px solid $color-primary-2;
    font-weight: 600;
  }

  .accordion-header-title {
    font-family: $font-family-secondary;
    font-weight: 600;
    font-size: 16px;
  }

  .allergies-pane-header {
    background-color: rgba($color-primary-2, 0.9);
  }

  .health-stats-pane-header {
    background-color: rgba($color-primary-2, 0.8);
  }

  .accordion-title-icon {
    margin-right: 20px;
  }

  .find-services-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .find-services-btn {
    padding: 10px 20px;
    margin: 0 20px;
    background-color: $color-primary-2;
    color: $text-color-primary;
  }

  .notes-and-contacts-header {
    padding: 20px;
    background-color: $color-white;
  }

  .consultations-list .box {
    background-color: $color-white;
    margin-bottom: 10px;
  }
  .box {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 120px;
    font-family: $font-family-secondary;
    background-color: $color-white;
  }

  .box-row {
    display: flex;
    width: 100%;
  }

  .box-row .box-cell {
    display: flex;
    flex: 1;
    padding: 10px 0;
  }

  .box-row .box-cell.title {
    font-weight: 500;
    padding-left: 10px;
  }

  .box-row-header {
    font-weight: 500;
    background-color: #ccc;
    padding-left: 10px;
  }

  .box-row.actions {
    position: relative;
  }

  .box-row.actions .confirm {
    position: absolute;
    width: 100%;
    z-index: 3;
    /* flex: 1; */
    display: flex;
    left: 0;
    background-color: $color-white;
    height: 60px;
    top: 0;
    justify-content: center;
    align-items: center;
  }

  .health-stats-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: $font-family-secondary;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .list-toggle-head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-secondary;
    font-size: 14px;
    color: $text-color-background;
  }

  .health-stats .box {
    background-color: $color-white;
  }

  .confirm-yes,
  .confirm-no {
    padding: 10px;
    font-family: $font-family-primary;
    font-weight: 600;
  }

  .confirm-yes {
    color: $color-red;
  }
  .confirm-no {
    color: $color-primary-2;
  }

  .btn.flat.action-delete {
    color: $color-red;
  }

  .list {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .btn-link {
    background-color: transparent;
    color: $color-primary-2;
  }
}
